<template>
  <div>
    <!-- 內容區塊1-為什麼選擇數位家教 -->
    <div class="main_block_one">
      <div class="main_block_one_container">
        <div class="main_block_one_left animate" data-ani="fadeInUp">
          <div class="main_block_one_title">
            為什麼選擇數位家教
            <span>？</span>
          </div>
          <div class="main_block_one_text">
            <img alt src="../assets/image/text_icon_1.jpg" />不會規劃學習計畫？
          </div>
          <div class="main_block_one_text">
            <img alt src="../assets/image/text_icon_2.jpg" />始終找不到合宜的老師？
          </div>
          <div class="main_block_one_text">
            <img alt src="../assets/image/text_icon_3.jpg" />使用講義題庫沒有效率？
          </div>
        </div>
      </div>
    </div>
    <!-- 內容區塊1-為什麼選擇數位家教end -->
    <!-- 內容區塊2-選擇好師到分數不會掉 -->
    <div class="main_block_two">
      <div class="main_block_two_container">
        <div class="main_block_two_title">選擇好師到，分數不會掉</div>
        <div class="main_block_two_text_area">
          <div
            class="main_block_two_text_container main_block_two_text_position_1 animate"
            data-ani="fadeInUp"
          >
            <div class="main_block_two_text">
              <div>
                <span>●</span>雙向記錄
              </div>
              <div>效率學習</div>
            </div>
          </div>
          <div
            class="main_block_two_text_container main_block_two_text_position_2 animate"
            data-ani="fadeInUp"
          >
            <div class="main_block_two_text">
              <div>
                <span>●</span>興趣導向
              </div>
              <div>個性學習</div>
            </div>
          </div>
          <div
            class="main_block_two_text_container main_block_two_text_position_3 animate"
            data-ani="fadeInUp"
          >
            <div class="main_block_two_text">
              <div>
                <span>●</span>三師一體
              </div>
              <div>全面補強</div>
            </div>
          </div>
          <div
            class="main_block_two_text_container main_block_two_text_position_4 animate"
            data-ani="fadeInUp"
          >
            <div class="main_block_two_text">
              <div>
                <span>●</span>師資篩選
              </div>
              <div>搭配好師</div>
            </div>
          </div>
          <div
            class="main_block_two_text_container main_block_two_text_position_5 animate"
            data-ani="fadeInUp"
          >
            <div class="main_block_two_text">
              <div>
                <span>●</span>適性適才
              </div>
              <div>課後作業</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 內容區塊2-選擇好師到分數不會掉end -->
    <!-- 內容區塊3-優選全國好老師 -->
    <div class="main_block_three">
      <div class="main_block_three_cover">
        <div class="main_block_three_container">
          <div class="main_block_three_title block_three_title_1920">優選全國好老師，嚴格篩選專業培訓</div>
          <div class="main_block_three_title block_three_title_550">
            優選全國好老師
            <br />嚴格篩選專業培訓
          </div>
          <div class="main_block_three_text_container">
            <div class="main_block_three_text animate" data-ani="fadeInUp">
              <div>
                <div class="block_three_text_title">懂家長</div>
                <div class="block_three_text_text">理解家長心情</div>
                <div class="block_three_text_text">協助家長溝通</div>
              </div>
            </div>
            <div class="main_block_three_text animate" data-ani="fadeInUp">
              <div>
                <div class="block_three_text_title">懂學生</div>
                <div class="block_three_text_text">明白學生心理</div>
                <div class="block_three_text_text">智解學生問題</div>
              </div>
            </div>
            <div class="main_block_three_text animate" data-ani="fadeInUp">
              <div>
                <div class="block_three_text_title">懂考試</div>
                <div class="block_three_text_text">緊抓知識點、考點</div>
                <div class="block_three_text_text">熟練答題技巧</div>
              </div>
            </div>
            <div class="main_block_three_text animate" data-ani="fadeInUp">
              <div>
                <div class="block_three_text_title">懂政策</div>
                <div class="block_three_text_text">洞悉教育改革</div>
                <div class="block_three_text_text">指導升學規劃</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 內容區塊3-優選全國好老師end -->
    <!-- 內容區塊4-學習規劃師 -->
    <div class="main_block_four">
      <div class="main_block_four_container">
        <div class="main_block_four_right_top">
          <div class="main_block_four_right_title">
            <div class="four_right_title_top">
              <span class="title_top_1920">為孩子鋪上專屬的道路</span>
              <span class="title_top_480">為孩子鋪上專屬的道路</span>
            </div>
            <div class="four_right_title_bottom">學習規劃師</div>
          </div>
          <div class="main_block_four_right_item_green animate" data-ani="fadeInUp">檢測孩子的性向及能力</div>
          <div class="main_block_four_right_item_green animate" data-ani="fadeInUp">配對個性化的專業師資</div>
          <div class="main_block_four_right_item_green animate" data-ani="fadeInUp">建立規律性的學習經歷</div>
          <div class="main_block_four_right_item_green animate" data-ani="fadeInUp">定期聯繫增強學習成效</div>
          <div style="clear:both;"></div>
        </div>
        <div class="main_block_four_right_line"></div>
        <div class="main_block_four_right_bottom">
          <div class="main_block_four_right_item_black animate" data-ani="fadeInUp">
            <div>
              <div class="four_right_item_black_icon">
                <img alt src="../assets/image/four_right_item_black_icon_1.jpg" />
              </div>
              <div class="four_right_item_black_title">學生好助手</div>
              <div class="four_right_item_black_text">找出學習關鍵</div>
              <div class="four_right_item_black_text">個性化課表</div>
              <div class="four_right_item_black_text four_right_mobile_none">專人作業訂正</div>
            </div>
          </div>
          <div class="main_block_four_right_item_black animate" data-ani="fadeInUp">
            <div>
              <div class="four_right_item_black_icon">
                <img alt src="../assets/image/four_right_item_black_icon_2.jpg" />
              </div>
              <div class="four_right_item_black_title">家長小幫手</div>
              <div class="four_right_item_black_text">即時上課資訊</div>
              <div class="four_right_item_black_text four_right_mobile_none">透明化簽到表</div>
              <div class="four_right_item_black_text">完整進度規劃</div>
            </div>
          </div>
          <div class="main_block_four_right_item_black animate" data-ani="fadeInUp">
            <div>
              <div class="four_right_item_black_icon">
                <img alt src="../assets/image/four_right_item_black_icon_3.jpg" />
              </div>
              <div class="four_right_item_black_title">學霸大推手</div>
              <div class="four_right_item_black_text">優質師資團隊</div>
              <div class="four_right_item_black_text four_right_mobile_none">用心輔導團隊</div>
              <div class="four_right_item_black_text">堅持理想的您</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 內容區塊4-學習規劃師end -->
    <!-- 內容區塊5-完整學習策略 -->
    <div class="main_block_five">
      <div class="main_block_five_cover">
        <div class="main_block_five_container">
          <div class="main_block_five_container_row">
            <div
              class="five_container_row_left appear-animation pup_block_five_1_btn"
              data-ani="fadeInUp"
            >
              <div>
                學習規劃師
                <br />作完整<span></span>學習評估
              </div>
            </div>
            <div class="five_container_row_center">
              <img alt src="../assets/image/main_block_five_arrow_r.png" />
            </div>
            <div
              class="five_container_row_right appear-animation pup_block_five_2_btn"
              data-ani="fadeInUp"
            >
              <div>
                iTeacher
                <br />打造<span>扎實</span>基礎
              </div>
            </div>
          </div>
          <div class="main_block_five_container_row">
            <div class="five_container_row_left" style="border:0px;">
              <img alt src="../assets/image/main_block_five_arrow_b.png" />
            </div>
            <div class="five_container_row_center">
              <span class="center_text_1920">完整學習策略</span>
              <span class="center_text_750">
                完整
                <br />學習策略
              </span>
              <span class="center_text_550">
                學習
                <br />策略
              </span>
            </div>
            <div class="five_container_row_right" style="border:0px;">
              <img alt src="../assets/image/main_block_five_arrow_t.png" />
            </div>
          </div>
          <div class="main_block_five_container_row">
            <div
              class="five_container_row_left appear-animation pup_block_five_3_btn"
              data-ani="fadeInUp"
            >
              <div>
                iTutor
                <br />幫您補弱增強
              </div>
            </div>
            <div class="five_container_row_center">
              <img alt src="../assets/image/main_block_five_arrow_l.png" />
            </div>
            <div
              class="five_container_row_right appear-animation pup_block_five_4_btn"
              data-ani="fadeInUp"
            >
              <div>
                做題強化
                <br />提升熟練度
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 內容區塊5-完整學習策略end -->
    <!-- 內容區塊6-影音介紹 -->
    <div class="main_block_six">
      <div class="main_block_six_container">
        <div class="main_block_six_video animate" data-ani="fadeInUp">
          <div class="six_video_1" v-show="vedioId==2">
            <img alt src="../assets/image/index_video_test.jpg" />
            <iframe allowfullscreen frameborder="0" src="https://www.youtube.com/embed/oukCtmhgF9U"></iframe>
          </div>
          <div class="six_video_2" v-show="vedioId==1">
            <img alt src="../assets/image/index_video_test.jpg" />
            <iframe allowfullscreen frameborder="0" src="https://www.youtube.com/embed/MddPKR0bvjg"></iframe>
          </div>
          <div class="main_block_six_title">影音介紹</div>
          <div class="main_block_six_btn_container">
            <div
              class="main_block_six_btn six_video_2_btn"
              :class="{'active':vedioId==1}"
              @click="vedioId=1"
            >
              <span>iTutor</span>一對一家教
            </div>
            <div
              class="main_block_six_btn six_video_1_btn"
              :class="{'active':vedioId==2}"
              @click="vedioId=2"
            >
              <span>iTeacher</span>一對一輔導
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 內容區塊6-影音介紹end -->
    <!-- 內容區塊7-我們的承諾 -->
    <div class="main_block_seven">
      <div class="main_block_seven_container">
        <div class="main_block_seven_left">
          <div class="main_block_seven_left_title">我們的承諾</div>
          <div class="main_block_seven_left_item_container">
            <div class="main_block_seven_left_item animate" data-ani="fadeInUp">
              <div class="seven_left_item_img">
                <img alt src="../assets/image/seven_left_item_1.jpg" />
              </div>
              <div>量身訂做</div>
              <div>因材施教</div>
            </div>
            <div class="main_block_seven_left_item animate" data-ani="fadeInUp">
              <div class="seven_left_item_img">
                <img alt src="../assets/image/seven_left_item_2.jpg" />
              </div>
              <div>學生客製化</div>
              <div>專屬課程</div>
            </div>
            <div class="main_block_seven_left_item animate" data-ani="fadeInUp">
              <div class="seven_left_item_img">
                <img alt src="../assets/image/seven_left_item_3.jpg" />
              </div>
              <div>教學不滿意</div>
              <div>老師隨時換</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 內容區塊7-我們的承諾end -->
  </div>
</template>
<script>
import { Form } from "element-ui";
export default {
  data() {
    return {
      vedioId: 1, //Vedio 展示
      domList: [],
      domKeys: [],
    };
  },
  created() {
    // let token = window.localStorage.getItem("token");
    // if (token) {
    //   this.isShowReg = JSON.parse(token).isStudent ? false : true;
    // }
  },
  methods: {
    //入场动画
    handleAnimate() {
      let dom = document.querySelectorAll(".animate");
      this.domList = [].slice.call(dom);
      addEventListener("scroll", this.toAddAnimate);
    },
    toAddAnimate() {
      let top =
        pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let vh = document.documentElement.clientHeight;
      this.domList.length > 0 &&
        this.domList.forEach((v, i) => {
          if (this.domKeys.indexOf(i) > -1) {
          } else {
            let par = v.offsetParent; //return console.log(v,v.offsetParent,)
            let offsetTop = 0;
            if (
              par &&
              par.nodeName &&
              typeof par.nodeName == "string" &&
              par.nodeName.toLowerCase() == "body"
            ) {
              offsetTop += v.offsetTop; // 获取高度
            } else {
              while (par) {
                offsetTop += par.offsetTop;
                par = par.offsetParent;
              }
            }
            if (top + vh >= offsetTop && top <= offsetTop + vh / 2) {
              var delay = v.dataset.delay;
              if (delay) {
                setTimeout(() => {
                  v.style.opacity = 1;
                  v.classList.add(v.dataset.ani);
                  this.domKeys.push(i);
                }, delay);
              } else {
                v.style.opacity = 1;
                v.classList.add(v.dataset.ani);
                this.domKeys.push(i);
              }
            } else {
              v.classList.remove(v.dataset.ani);
              v.style.opacity = 0;
            }
          }
        });
    },
  },
  mounted() {
    // this.handleAnimate();
    let dom = document.querySelectorAll(".animate");
    this.domList = [].slice.call(dom);
    this.toAddAnimate();
    addEventListener("scroll", this.toAddAnimate);
    //初始化第一次加载时在视口内就执行动画
  },
  destroyed() {
    window.removeEventListener("scroll", this.getScroll);
    window.removeEventListener("scroll", this.toAddAnimate);
  },
};
</script>

<style scoped>
@import "../assets/css/about.css";
.fadeInUp {
  animation: fadeInUp 3s;
}
</style>